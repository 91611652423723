import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

function ResourceIcon(props: SvgIconProps) {
  return (
    <SvgIcon width={21} height={28} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill="currentColor"
        d="M4 4v12a2 2 0 0 0 2 2h9.5a.5.5 0 0 0 0-1H6a1 1 0 0 1-1-1h10a1 1 0 0 0 1-1V4a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2m10-1a1 1 0 0 1 1 1v11H5V4a1 1 0 0 1 1-1zm-3.25 2.75a.75.75 0 1 0-1.5 0a.75.75 0 0 0 1.5 0m-.25 6.75a.5.5 0 0 1-1 0v-4a.5.5 0 0 1 1 0z"
      />
    </SvgIcon>
  );
}

export default ResourceIcon;
