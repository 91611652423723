import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

function SettingsIcon(props: SvgIconProps) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32" {...props}>
      <path
        stroke="currentColor"
        fill="none"
        strokeWidth={1.5}
        d="M21 16a5 5 0 1 1-10 0a5 5 0 0 1 10 0m-1 0a4 4 0 1 0-8 0a4 4 0 0 0 8 0M5.9 6.304a.96.96 0 0 1 1.095-.193l1.8.83a2 2 0 0 0 2.82-1.546l.297-2.176a.96.96 0 0 1 .71-.809A14 14 0 0 1 15.999 2c1.165 0 2.296.142 3.378.41a.96.96 0 0 1 .71.809l.297 2.176a2 2 0 0 0 2.819 1.546l1.8-.83a.96.96 0 0 1 1.095.193a14 14 0 0 1 3.35 5.795a.96.96 0 0 1-.382 1.045l-1.736 1.22a2 2 0 0 0 0 3.273l1.736 1.22a.96.96 0 0 1 .382 1.044a14 14 0 0 1-3.35 5.795a.96.96 0 0 1-1.094.193l-1.802-.83a2 2 0 0 0-2.818 1.546l-.297 2.176a.96.96 0 0 1-.71.809a14 14 0 0 1-3.378.41a14 14 0 0 1-3.378-.41a.96.96 0 0 1-.71-.809l-.296-2.176a2 2 0 0 0-2.82-1.546l-1.8.83a.96.96 0 0 1-1.095-.193A14 14 0 0 1 2.55 19.9a.96.96 0 0 1 .382-1.045l1.737-1.22a2 2 0 0 0 0-3.273l-1.737-1.22a.96.96 0 0 1-.382-1.045A14 14 0 0 1 5.9 6.305m-2.378 6.032l1.721 1.209c1.701 1.195 1.701 3.715 0 4.91l-1.72 1.209a13 13 0 0 0 3.07 5.31l1.784-.823a3 3 0 0 1 4.228 2.318l.295 2.16c.992.242 2.03.371 3.1.371a13 13 0 0 0 3.098-.371l.295-2.16a3 3 0 0 1 4.228-2.318l1.785.822a13 13 0 0 0 3.07-5.31l-1.72-1.208a3 3 0 0 1 0-4.91l1.72-1.209a13 13 0 0 0-3.07-5.31l-1.785.823a3 3 0 0 1-4.228-2.318l-.295-2.16A13 13 0 0 0 15.999 3c-1.069 0-2.107.129-3.099.371l-.295 2.16a3 3 0 0 1-4.228 2.318l-1.784-.822a13 13 0 0 0-3.07 5.31"
      />
    </SvgIcon>
  );
}

export default SettingsIcon;
